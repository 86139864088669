<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="1">
        <!-- <v-btn color="warning" @click="$router.push({path: '/rails/convogli/lavorazione'})">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn> -->
        <v-btn color="warning" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="11" class="text-h5 font-weight-regular"> Lavorazione carico convoglio {{ convoy.nome }} </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <ConvoyDepartureExecution :convoy="convoy" :voyage="voyage" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ToastMixin from "../../mixins/ToastMixin";
import ConvoyDepartureExecution from "../../components/misc/ConvoyDepartureExecution";

export default {
  name: "LavorazioneConvoglioPartenza",
  components: {
    ConvoyDepartureExecution,
  },
  mixins: [ToastMixin],
  props: ["convoyId", "voyageId"],
  data() {
    return {
      convoy: {},
      voyage: {},
    };
  },
  async mounted() {
    try {
      this.convoy = await this.$api.get(this.$apiConfiguration.BASE_PATH + "convogli/" + this.convoyId);
      if (this.voyageId) {
        this.voyage = await this.$api.trainData.getViaggioById(+this.voyageId);
      } else {
        this.voyage = undefined;
      }
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style scoped>
</style>