<template>
  <div>
    <v-expansion-panels v-model="editPanel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
          INFO CONVOGLIO
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ConvoyInfoPanelFull :convoglio="convoy" isExecution :executionVoyage="voyage" isDepartureExecutionVoyage/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card class="my-2">
      <v-card-text>
        <v-container fill-height fluid>
          <v-row align="center" justify="center">            
            <v-col class="ma-0 pa-0">
              <!-- <v-btn block x-large :color="colorBtnProntoUscita" @click="prontoUscita()">
                <span :class="`${colorBtnTextProntoUscita}--text text-h5 text-center font-weight-regular`">Pronto Uscita</span>
              </v-btn> -->
              <v-btn block x-large :color="colorBtnProntoUscita" @click="prepareProntoUscita()">
                <span :class="`${colorBtnTextProntoUscita}--text text-h5 text-center font-weight-regular`">Pronto Uscita</span>
              </v-btn>
            </v-col>
            <v-col class="text-h4 text-center font-weight-regular ma-0 pa-0">
              <div>
                Carri caricati: {{ loadedWagons }} / {{ toLoadWagons }} / {{ totalWagons }}
              </div>
              <div>
                Uti caricate: {{ loadedUti }} / {{ toLoadUti }} / {{ totalUti }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title class="text-body-1 text-uppercase font-weight-regular py-2 my-2 panelheader">
        LAVORAZIONE CARRI CONVOGLIO
      </v-card-title>
      <v-card-text>
        <v-container class="py-0 my-0">
          <v-row class="pb-2 my-2">
            <v-col cols="3">
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"                 
                  label="Cerca"
                  single-line
                  hide-details
                  clearable
                  class="text-h5 font-weight-bold"
                />
            </v-col>
          </v-row>
        </v-container>

        <ConvoyDepartureExecutionViewPanel :convoglio="convoy" :convoyWagons="convoy.listCarri" :executionVoyage="voyage" :search="search"  />

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ConvoyInfoPanelFull from "../../components/misc/ConvoyInfoPanelFull";
import ConvoyDepartureExecutionViewPanel from "../../components/misc/ConvoyDepartureExecutionViewPanel";
import toastMixin from "../../mixins/ToastMixin";

export default {
  name: "ConvoyDepartureExecution",
  components: {
    ConvoyInfoPanelFull,
    ConvoyDepartureExecutionViewPanel,
  },
  mixins: [toastMixin],
  data() {
    return {
      editPanel: [0],
      search:null,
     
    };
  },
  
  props: {
    convoy: {
      type: Object,
      required: true
    },
    voyage: {
      type: Object,
      required: false
    }
  },
  computed: {
    colorBtnProntoUscita() {
      if (this.convoy.stato && (this.convoy.stato.toLowerCase() === "prontouscita")) {
        return "blue lighten-1";
      } else {
        return "blue lighten-5";
      }
    },
    colorBtnTextProntoUscita() {
      if (this.convoy.stato && (this.convoy.stato.toLowerCase() === "prontouscita")) {
        return "white";
      } else {
        return "black";
      }
    },
    loadedWagons() {
      var loadedCount = 0;
      if (this.convoy && this.convoy.listCarri && this.convoy.listCarri.length>0) {
        this.convoy.listCarri.forEach(convoyWagon => {
          if ((!this.voyage || this.voyage.id===convoyWagon.viaggioOutId) && this.convoyWagonLoaded(convoyWagon)) {
            loadedCount++;
          }
        });
      }
      return loadedCount;
    },
    toLoadWagons() {
      var toLoadCount = 0;
      if (this.convoy && this.convoy.listCarri && this.convoy.listCarri.length>0) {
        this.convoy.listCarri.forEach(convoyWagon => {
          if ((!this.voyage || this.voyage.id===convoyWagon.viaggioOutId) && convoyWagon.listUti && convoyWagon.listUti.length>0) {              
            var importUtiList = convoyWagon.listUti.filter(utiWagon => utiWagon.tipoMovimento === "IMPORT" && utiWagon.rifiutato===false);     
            if (importUtiList && importUtiList.length>0) {
              toLoadCount++;
            }                   
          }
        });
      }
      return toLoadCount;
    },
    totalWagons() {
      var totalCount = 0;
      if (this.convoy.listCarri) {
        totalCount = this.convoy.listCarri.length;
      } 
      return totalCount;
    },
    loadedUti() {
      var loadedCount = 0;
      if (this.convoy && this.convoy.listCarri && this.convoy.listCarri.length>0) {
        this.convoy.listCarri.forEach(convoyWagon => {
          if (!this.voyage || this.voyage.id===convoyWagon.viaggioOutId) {
            loadedCount = loadedCount + this.utiLoadedByConvoyWagon(convoyWagon);
          }
        });
      }
      return loadedCount;
    },
    toLoadUti() {
      var toLoadCount = 0;
      if (this.convoy && this.convoy.listCarri && this.convoy.listCarri.length>0) {
        this.convoy.listCarri.forEach(convoyWagon => {
          if (!this.voyage || this.voyage.id===convoyWagon.viaggioOutId) {
            toLoadCount = toLoadCount + this.utiToLoadByConvoyWagon(convoyWagon);
          }
        });
      }
      return toLoadCount;
    },
    totalUti() {
      var totalUti= 0;
      if (this.convoy && this.convoy.listCarri && this.convoy.listCarri.length>0) {
        this.convoy.listCarri.forEach(convoyWagon => {
            totalUti = totalUti + this.utiTotalByConvoyWagon(convoyWagon);
        });
      }
      return totalUti;
    },
  },
  methods: {
    async prontoUscita() { 
        const fullPath = this.$apiConfiguration.BASE_PATH + "convogli/prontoUscita";
        const statoPrec = this.convoy.stato;
        if (this.convoy.stato) {
          if (this.convoy.stato.toLowerCase() === "interminal") {
            this.convoy.stato="PRONTOUSCITA";
          } else if (this.convoy.stato.toLowerCase() === "prontouscita") {
            this.convoy.stato = "INTERMINAL";
          }    
          if (statoPrec.toLowerCase() !== this.convoy.stato.toLowerCase()) {
            const reqObj = {
              convoglioId: this.convoy.id,
              stato: this.convoy.stato,
            };
            var res = await this.$api.post(fullPath, reqObj);
            this.convoy.processingTimeEnd = res.processingTimeEnd;
            this.convoystato = res.stato;
          }
          console.log("prontoUscita res", res);
          }     
    },

    async prepareProntoUscita() {
      try {
        if (this.convoy.stato.toLowerCase() === "interminal") {
          await this.$api.trainData.prepareProntoUscita(this.convoy).then((response) => {
          console.log("response prepare pronto uscita:", response);
          if(response!==""){
            if (response.success == false) {
               console.log("response prepare pronto uscita errore:", response.success);
              this.showError("ERRORE: I carri sono associati a più viaggi di uscita. Impossibile eseguire il PORNTO USCITA. Legare i carri ad un singolo viaggio");
            } else {
               console.log("response prepare pronto uscita warning:", response.success);
              this.showWarning("WARNING: Il viaggio di uscita del carro è associato ad altri carri associati ad altri convogli");
              this.prontoUscita();
            }
          }else{
            console.log("Pronto uscita");
            this.prontoUscita(); 
          }
          });
        
        }else{
          console.log("Pronto uscita");
          this.prontoUscita();
        }
      } catch (e) {
        console.log("Pronto uscita error:", e);
        this.showError(e.detailMessage || e);
      }
      // finally {
      //   this.$eventBus.$emit('convoyReload');
      // }
    },

    convoyWagonLoaded(convoyWagon) {
      var loadedCount = 0;
      var toLoadCount = 0;
      convoyWagon.listUti.forEach(utiWagon => {
        if (utiWagon.tipoMovimento === "IMPORT" && utiWagon.rifiutato === false) {
          toLoadCount++;       
          if (utiWagon.stato === "CARICATO") {
            loadedCount++;
          }
        }
      });
      if (toLoadCount !== 0 && loadedCount === toLoadCount) {
        return true;
      } else {
        return false;
      };
      return false;
    },
    utiLoadedByConvoyWagon(convoyWagon) {
      var loadedCount = 0;
      if (convoyWagon.listUti && convoyWagon.listUti.length>0) {
        var loadedUtiList = convoyWagon.listUti.filter(utiWagon => utiWagon.tipoMovimento === "IMPORT" && utiWagon.stato === "CARICATO");
        if (loadedUtiList && loadedUtiList.length>0) {
          loadedCount = loadedUtiList.length;
        }        
      }
      return loadedCount;
    },
    utiToLoadByConvoyWagon(convoyWagon) {
      var toLoadCount = 0;
      if (convoyWagon.listUti && convoyWagon.listUti.length>0) {
        var toLoadUtiList = convoyWagon.listUti.filter(utiWagon => utiWagon.tipoMovimento === "IMPORT" && utiWagon.rifiutato === false);
        if (toLoadUtiList && toLoadUtiList.length>0) {
          toLoadCount = toLoadUtiList.length;
        }     
      }
      return toLoadCount;
    },
    utiTotalByConvoyWagon(convoyWagon) {
      var totalCount = 0;
      if (convoyWagon.listUti && convoyWagon.listUti.length>0) {
        var totalUtiList = convoyWagon.listUti.filter(utiWagon => utiWagon.tipoMovimento === "IMPORT" || utiWagon.remainOnBoardScarico===true);
        if (totalUtiList && totalUtiList.length>0) {
          totalCount = totalUtiList.length;
        }     
      }
      return totalCount;
    },
  }
};
</script>

<style scoped>

</style>