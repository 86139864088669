<template>
  <v-card class="dialog">
    <v-card-title>
        Seleziona quantita merce da caricare  
    </v-card-title>
    
    <v-card-text>
      <v-row align="center" >
        <v-col cols="3">
          <b>{{unit.codiceUti || unit.merce}}</b>
        </v-col>
        <v-col cols="4" v-if="isGoodsByItem()">
          <v-text-field :label="tLabel('Quantity')" v-model="unit.colli"  style="width:100px; text-align:right" />  
        </v-col>
        <v-col cols="4" v-if="isGoodsByItem()">
          <div>/ {{unit.numeroColli}}</div>
        </v-col>
        <v-col cols="4" v-if="isGoodsByCount()">
          <v-checkbox :label="tLabel('Svuotamento finale')" v-model="unit.isCompleted" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn v-on:click="$emit('ok')" :disabled="okDisabled" small>OK</v-btn>
      <v-btn v-on:click="$emit('cancel')" small>Annulla</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
    
    name: "GoodVisitLoad", 
    components: {},
    props: {
        unit: { type: Object},
    },
    //mixins: [],
    data() {
        return {
        };
    },
    computed: {
      okDisabled() {
        return this.isGoodsByItem() && !(this.unit.colli>0);
      }
    },
    methods: {
      isGoodsByItem(){
        return this.unit.countType == 'I';
      },
      isGoodsByCount(){
        return this.unit.countType == 'L';
      },
    }
}
</script>

<style scoped>
</style>