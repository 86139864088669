<template>

  <v-card min-width="400" :disabled="disabledWagon" @click="goToLavorazioneCaricoCarroConvoglio()" :class="`wagon ${highlighted===true?'highlighted':''}`" >
    <v-app-bar flat :color="disabledWagon ? 'grey darken-2' : 'warning'">
      <v-toolbar-title class="text-h5 font-weight-black">{{ convoyWagon.visitaCarro.targa }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-chip class="text-caption header ma-2"
        ><b>{{ this.labelViaggioPartenza }}</b></v-chip
      >

      <v-chip class="text-body-1 header ma-1"
        ><b>{{ convoyWagon.posizione }}</b></v-chip
      >
    </v-app-bar>

    <v-card-text class="grey lighten-2">
      <v-container class="pa-0 ma-0">
        <v-row align="center" justify="center" class="pa-0 ma-0">
          <v-col v-if="utiWagonDepartureList && utiWagonDepartureList.length > 0" class="pa-0 ma-0">
            <v-sheet
              elevation="1"
              rounded="lg"
              :class="`text-body-1 font-weight-regular pa-3 ma-3 ${getClass(utiCarro)}`"
              v-for="utiCarro in utiWagonDepartureList"
              :key="utiCarro.id"
            >
              <v-container class="pa-0 ma-0">
                <v-row class="pa-0 ma-0" justify="center">
                  <v-col :cols="!utiCarro.linked ? 10 : 12" class="font-weight-bold pa-0 ma-0">
                    <v-sheet @click.stop="caricaAnnullaCaricoUtiCarro(utiCarro)" :disabled="disableUtiCarro(utiCarro, 'CARICA')" style="background-color: transparent;">
                      {{ displayUtiCarro(utiCarro) }}
                    </v-sheet>
                  </v-col>
                  <v-col cols="2" align="end" class="pa-0 ma-0" v-if="!utiCarro.linked">
                    <v-icon color="secondary">mdi-calendar-edit</v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
            <v-sheet
              elevation="1"
              rounded="lg"
              class="text-center text-body-1 blue pa-3 ma-3 lighten-2 font-weight-black"
              @click="goToLoadingPanel()"
            ><v-icon style="color: inherit;">mdi-plus</v-icon></v-sheet>
          </v-col>

          <v-col v-else class="pa-1 ma-1">
            <v-sheet v-if="disabledWagon" elevation="1" rounded="lg" class="text-body-1 font-weight-regular">
              NO UTI
            </v-sheet>
            <v-btn block large v-else elevation="1" class="text-center text-body-1 blue lighten-2 rounded-lg" @click.stop="goToLoadingPanel()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
       <v-dialog v-model="showGoodVisitLoad" persistent width="600" v-if="showGoodVisitLoad">
        <GoodVisitLoad :unit="utiCarroSelected.visitaUti" v-on:ok="doProcessMerceVaria"  v-on:cancel="showGoodVisitLoad = false" />
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import GoodVisitLoad from "@/components/rails/GoodVisitLoad";
import railsMixins from "../rails/mixins/railsMixins";

export default {
  name: "WagonDepartureExecutionViewPanel",
  components: {GoodVisitLoad},
  mixins: [railsMixins],
  data() {
    return {
      search: null,
      aggiungiUtiDialog: false,
      tabs: null,
      visiteUti: {},
      carriViaggi: {},
      visiteNonUscite: {},
      filteredUti: [],
      showGoodVisitLoad: false,
      utiCarroSelected: null,
      visiteUtiHeaders: [
        { text: "", value: "action", class: "header text-uppercase" },
        { text: "Tipo Uti", value: "", class: "header text-uppercase" },
        { text: "F/E", value: "", class: "header text-uppercase" },
        { text: "Operator", value: "", class: "header text-uppercase" },
        { text: "Merce", value: "merce", class: "header text-uppercase" },
        { text: "Polizza", value: "polizza", class: "header text-uppercase" },
      ],
    };
  },
  props: {
    convoglio: {
      type: Object,
      required: true,
    },
    convoyWagon: {
      type: Object,
      required: true,
    },
    executionVoyageWagon: {
      type: Object,
      required: false,
    },
    disabledWagon: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchUti: null,
    tabsRendered: {
      type: Array,
      default: () => [true, true, true],
      required: false,
    },
    executionVoyage: {
      type: Object,
      required: false,
    },
    highlighted: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    labelConvenzionale() {
      if (this.convoyWagon) {
        if (this.convoyWagon.visitaCarro.carro.tipoCarro.convenzionale) {
          return "CONV.";
        } else {
          return "NON CONV.";
        }
      } else {
        return "";
      }
    },
    labelViaggioPartenza() {
      if (this.convoyWagon) {
        if (this.convoyWagon.viaggioOut && this.convoyWagon.viaggioOut.nodoArrivo) {
          return this.convoyWagon.viaggioOut.nodoArrivo.descrizione;
        } else return "";
      }
    },
    utiWagonDepartureList() {
      var wdl = [];
      if (this.convoyWagon.listUti && this.convoyWagon.listUti.length > 0) {
        wdl = this.convoyWagon.listUti.filter(utiWagon => utiWagon.tipoMovimento === "IMPORT" || utiWagon.remainOnBoardScarico);

        wdl.forEach(w => {
          w.linked = true;
        });
      }
      //carroviaggio
      if (this.executionVoyageWagon) {
        if (this.executionVoyageWagon.listUti && this.executionVoyageWagon.listUti.length > 0) {
          this.executionVoyageWagon.listUti.forEach(utiWagonInExecutionVoyage => {
            if (wdl && wdl.length > 0) {
              var uwdl = wdl.filter(utiWagon => utiWagon.visitaUtiId === utiWagonInExecutionVoyage.visitaUtiId);
              if (!uwdl || uwdl.length === 0) {
                utiWagonInExecutionVoyage.linked = false;
                wdl.push(utiWagonInExecutionVoyage);
              }
            } else {
              utiWagonInExecutionVoyage.linked = false;
              wdl.push(utiWagonInExecutionVoyage);
            }
          });
        }
      }
      return wdl;
    },
  },
  methods: {
    caricaAnnullaCaricoUtiCarro(utiCarro) {
      if (utiCarro.stato === 'PIANIFICATO' && utiCarro.visitaUti.countType === 'L') {
        this.utiCarroSelected=utiCarro;
        this.showGoodVisitLoad = true;
      } else {
        this.doCaricaAnnullaCaricoUtiCarro(utiCarro);
      }
    },

    async doProcessMerceVaria() {
      console.log("doProcessMerceVaria isCompleted?", this.utiCarroSelected.visitaUti.isCompleted);
      this.showGoodVisitLoad=false;
      this.doCaricaAnnullaCaricoUtiCarro(this.utiCarroSelected);
    },

    async doCaricaAnnullaCaricoUtiCarro(utiCarro) {
      const stato = utiCarro.stato === "PIANIFICATO" ? "CARICATO" : "PIANIFICATO";
      try {
        await this.$api.trainData.caricaUtiCarroToCarroConvoglio(this.convoglio.id, this.convoyWagon.id, utiCarro.id, stato, utiCarro.visitaUti.isCompleted);
        if (utiCarro.stato === "PIANIFICATO") {
          utiCarro.carroConvoglioId = this.convoyWagon.id;
          utiCarro.stato = "CARICATO";
        } else {
          utiCarro.carroConvoglioId = null;
          utiCarro.stato = "PIANIFICATO";
        }
      } catch (e) {
        this.showError(e);
      }
    },

    setWagonOnFocusRef() {
      const data = {key:this.$router.history.current.path , wagonOnFocusRef:'wagon_'+this.convoyWagon.id+'_'+this.convoyWagon.posizione};
      this.$store.commit('configuration/setWagonOnFocusRef', data);
    },

    goToLavorazioneCaricoCarroConvoglio() {
      this.setWagonOnFocusRef();
      if (this.executionVoyageWagon) {
        this.$router.push({
          path: "/callTreno/LavorazioneCaricoCarroConvoglio/" + this.convoglio.id + "/" + this.convoyWagon.id + "/" + this.executionVoyageWagon.id,
        });
      } else {
        this.$router.push({
          path: "/callTreno/LavorazioneCaricoCarroConvoglio/" + this.convoglio.id + "/" + this.convoyWagon.id,
        });
      }     
      return;
    },
    getClass(utiWagon) {
      if (utiWagon.visitaUti.yardGateIn === null) {
        return "grey lighten-1";
      }

      if (utiWagon.remainOnBoardScarico && utiWagon.remainOnBoardScarico === true) {
        return "uti-rob";
      }
      if (utiWagon.rifiutato === true) {
        if (this.disabledWagon) {
          return "grey lighten-1";
        } else {
          return "red lighten-2";
        }
      }
      if (utiWagon.stato === "CARICATO") {
        if (this.disabledWagon) {
          return "grey lighten-1";
        } else {
          return "blue lighten-2";
        }
      }
      if (utiWagon.linked) {
        if (this.disabledWagon) {
          return "grey lighten-4";
        } else {
          return "blue lighten-5";
        }
      }
    },
    setFilteredUti(filteredUti) {
      this.filteredUti = filteredUti;
    },
    disableUtiCarro(utiCarro, btnType) {
      if (
        (utiCarro.remainOnBoardScarico && utiCarro.remainOnBoardScarico === true) ||
        (btnType === "CARICA" && utiCarro.rifiutato === true) ||
        (btnType === "RIFIUTA" && utiCarro.stato === "CARICATO") ||
        (btnType === "RIMUOVI" && utiCarro.stato !== "PIANIFICATO")
      ) {
        return "disabled";
      }
    },
    colorBtnCarica(utiCarro) {
      if (utiCarro.stato === "CARICATO") {
        return "primary";
      } else {
        return "blue lighten-2";
      }
    },
    classRemainOnBoard(utiCarro) {
      if (utiCarro.remainOnBoardScarico && utiCarro.remainOnBoardScarico === true) {
        return "uti-rob";
      }
    },


    goToLoadingPanel() {
      this.setWagonOnFocusRef();
      console.log("this.convoyWagon.viaggioOutId: ", this.convoyWagon.viaggioOutId);
      this.$router.push({ path: "/callTreno/CaricoCarroConvoglio/" + this.convoyWagon.id });
    },
    targaCarro() {
      if (this.carroConvoglio && this.carroConvoglio.visitaCarro) {
        return this.carroConvoglio.visitaCarro.targa;
      }
    },
  },
};
</script>

<style scoped>
.wagon {
  vertical-align: top;
}
.highlighted {
  border: 5px solid#effe65 !important;
}

.uti-rob {
  background-image: repeating-linear-gradient(-45deg, #ffffff, #ffffff 5px, rgb(196, 196, 192) 5px, rgb(196, 196, 192) 10px) !important;
}
</style>
