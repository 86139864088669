<template>
  <div class="outer">
    <v-sheet
      v-for="convoyWagon in convoyWagonsByExecutionVoyage"
      :key="convoyWagon.id" 
    > <WagonDepartureExecutionViewPanel
        :convoglio="convoglio"
        :convoyWagon="convoyWagon"
        :executionVoyageWagon="convoyWagon.executionVoyageWagon"
        :disabledWagon="convoyWagon.disabledWagon"
        :executionVoyage="executionVoyage"      
        :ref="`wagon_${convoyWagon.id}_${convoyWagon.posizione}`"
        :highlighted="wagonOnFocusRef===`wagon_${convoyWagon.id}_${convoyWagon.posizione}`"
      />
    </v-sheet>
  </div>
</template>

<script>
import WagonDepartureExecutionViewPanel from "../../components/misc/WagonDepartureExecutionViewPanel";

export default {
  name: "ConvoyDepartureExecutionViewPanel",
  components: {
    WagonDepartureExecutionViewPanel,
  },
  mixins: [],
  data() {
    return {
      executionVoyageWagonsByWagonVisitsId: [],
      wagonOnFocusRef: {}
    };
  },
  props: {
    convoglio: {
       type: Object,
       required: true, 
    },
    convoyWagons: {
      type: Array,
      default: () => [],
    },
    executionVoyage: {
      type: Object,
      required: false,
    },
    search: null,
  },
  mounted() {

  },
  updated() {
    this.getFocus();
  },
  watch: {
    convoyWagons: async function () {
      await this.init();
    }
  },
  computed: {
    convoyWagonsByExecutionVoyage() {
      var cws = [];
      this.convoyWagons.forEach((convoyWagon) => {
        convoyWagon.disabledWagon = true;
        convoyWagon.executionVoyageWagon = undefined;
        if (this.executionVoyage) {
          if (this.executionVoyage.listCarri && this.executionVoyage.listCarri.length > 0) {
            this.executionVoyage.listCarri.forEach((executionVoyageWagon) => {
              if (convoyWagon.visitaCarro.id === executionVoyageWagon.visitaCarroId) {
                convoyWagon.disabledWagon = false;
                convoyWagon.executionVoyageWagon = executionVoyageWagon;
              }
            });
          }
        } else {
          convoyWagon.disabledWagon = convoyWagon.viaggioOutId==null; //se non viaggio dest, non si puo caricare!
          if (this.executionVoyageWagonsByWagonVisitsId) {
            this.executionVoyageWagonsByWagonVisitsId.forEach((executionVoyageWagon) => {
                if (executionVoyageWagon && executionVoyageWagon.length > 0 && executionVoyageWagon[0].visitaCarroId === convoyWagon.visitaCarro.id) {
                  convoyWagon.executionVoyageWagon = executionVoyageWagon[0];
                }                
              }
            );
          }
        }
        cws.push(convoyWagon);
      });
      if (this.search) {
        var cwsFilter = [];
        cws.forEach((c) => {
          if (c.visitaCarro.targa.toLowerCase().includes(this.search.toLowerCase())) {
            cwsFilter.push(c);
          }
          if (c.executionVoyageWagon) {
            c.executionVoyageWagon.listUti.forEach((utiExecution) => {
              if ( (utiExecution.visitaUti.codiceUti && utiExecution.visitaUti.codiceUti.toLowerCase().includes(this.search.toLowerCase())) ||
                   (utiExecution.visitaUti.merce && utiExecution.visitaUti.merce.toLowerCase().includes(this.search.toLowerCase()) && utiExecution.tipoMovimento === "IMPORT") ) {
                cwsFilter.push(c);
              }
            });
          }
          c.listUti.forEach((uti) => {
            if (
              ((uti.visitaUti.codiceUti &&
                uti.visitaUti.codiceUti
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
                (uti.visitaUti.merce &&
                  uti.visitaUti.merce
                    .toLowerCase()
                    .includes(this.search.toLowerCase()))) &&
              ( uti.tipoMovimento === "IMPORT" || uti.remainOnBoardScarico === true)
            ) {
              cwsFilter.push(c);
            }
          });
        });
        var uniqueItems = Array.from(new Set(cwsFilter));
        return uniqueItems;
      }
      // console.log("cws", cws);
      return cws;
    },
  },

  methods: {
    async init() {
      if (!this.executionVoyage) {
        this.convoyWagons.forEach(async (convoyWagon) => {
          var ewv = await this.getExecutionVoyageWagonsByWagonVisitsId(
            convoyWagon.visitaCarro.id
          );
          if (ewv && ewv.length > 0) {
            this.executionVoyageWagonsByWagonVisitsId.push(ewv);
          }
        });
      }     
    },
    getFocus() {      
      this.wagonOnFocusRef = this.$store.getters['configuration/wagonOnFocusRef'](this.$router.history.current.path);   
      if (this.wagonOnFocusRef && this.$refs[this.wagonOnFocusRef]) {     
        this.$refs[this.wagonOnFocusRef][0].$el.focus();
      }
    },
    async getExecutionVoyageWagonsByWagonVisitsId(wagonVisitsId) {
      return await this.$api.get(
        (await this.$apiConfiguration.BASE_PATH) +
          "carriViaggio/findCarriViaggioPerVisitaCarroIdTipoViaggio/" +
          wagonVisitsId +
          "/PARTENZA"
      );
    },
  },
};
</script>

<style scoped>
.outer {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.outer div {
  float: none;
  height: 100%;
  margin: 0 1%;
  display: inline-block;
}
</style>